import '../assets/JsonTable.css';

function Table({ json }) {
    const renderNoData = () => {
        return <tr>
            <td>No data found</td>
        </tr>;
    }

    const processJsonArray = (jsonArray) => {
        if (!jsonArray.length) {
            return renderNoData();
        }

        let uniqueKeys = [];
        jsonArray.forEach((row) => {
            const rowKeys = Object.keys(row);
            uniqueKeys = [...new Set([...uniqueKeys, ...rowKeys])];
        });

        return <>
            <tr>
                {uniqueKeys.map((key, index) => {
                    return <th key={index+key}>{key}</th>
                })}
            </tr>
            {jsonArray.map((row) => {
                return <tr className="is-array" key={JSON.stringify(row)}>
                    {uniqueKeys.map((key, index) => {
                        return <td key={index}>
                            {typeof row[key] === 'object'
                            ? <table className="kantar-table kantar-nested-table">
                                <tbody>{processJsonArray(row[key])}</tbody>
                            </table>
                            : row[key]}
                        </td>
                    })}
                </tr>
            })}
        </>
    };

    const processJsonObject = (jsonObject) => {
        const objectKeys = Object.keys(jsonObject);
        if (!objectKeys.length) {
            return renderNoData();
        }

        return Object.keys(jsonObject).map((key) => {
            return <tr key={JSON.stringify(jsonObject[key])}>
                <td className="header">{key.toString()}</td>
                <td>
                    {Array.isArray(jsonObject[key])
                        ? <table className="kantar-table kantar-nested-table">
                            <tbody>{processJsonArray(jsonObject[key])}</tbody>
                        </table>
                        : jsonObject[key]}
                </td>
            </tr>
        });
    }

    const processJson = (newJson) => {
        if (!newJson) {
            return renderNoData();
        }

        if (Array.isArray(newJson)) {
            return processJsonArray(newJson);
        } else {
            return processJsonObject(newJson);
        }
    };

    return (
        <table className="kantar-table">
            <tbody>
                {/* <tr><td>Test</td></tr> */}
                {processJson(json)}
            </tbody>
        </table>
    );
}

export default Table;