import axios from 'axios';
import { useState } from 'react';
import UploadOutlined from '@ant-design/icons/UploadOutlined';

import '../assets/Home.css';
import { API_URL } from '../config';
import JsonTable from '../components/JsonTable';

function Home({ account, apiKey }) {
    const [selectedFile, setSelectedFile] = useState();
    const [processedJSON, setProcessedJSON] = useState();
    const [errors, setErrors] = useState();
    const [isLoading, setIsLoading] = useState(false);

    // On file select (from the pop up)
    const onFileChange = event => {
        var target = event.target || event.srcElement;
        if (target.value.length === 0 && !target.files.length) {
            return;
        }
        // Update the state
        setProcessedJSON(null);
        setErrors(null);
        setSelectedFile(event.target.files[0]);
    };

    // On file upload (click the upload button)
    const onFileUpload = async () => {
        if (!selectedFile) {
            alert('No file selected!');
            return;
        }

        setIsLoading(true);

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            'xmlFile',
            selectedFile,
            selectedFile.name
        );
        formData.append('apiKey', apiKey);

        // Details of the uploaded file
        // console.log(selectedFile);

        // Request made to the backend api
        // Send formData object
        try {
            const response = await axios.post(`${API_URL}/api/file`, formData);
            setProcessedJSON(response.data?.convertedJson);
            setErrors(response.data?.errors);
        } catch (error) {
            console.log(error?.response);
            setErrors(error?.response?.data);
        }

        setIsLoading(false);
    };

    // const downloadObjectAsJson = (exportObj, exportName) => {
    //     const dataStr = `data:text/json;charset=utf-8, ${encodeURIComponent(JSON.stringify(exportObj, undefined, 2))}`;
    //     const downloadAnchorNode = document.createElement('a');
    //     downloadAnchorNode.setAttribute('href', dataStr);
    //     downloadAnchorNode.setAttribute('download', `${exportName}.json`);
    //     document.body.appendChild(downloadAnchorNode); // required for firefox
    //     downloadAnchorNode.click();
    //     downloadAnchorNode.remove();
    // };

    // File content to be displayed after
    // file upload is complete
    const renderFileInfo = () => {
        if (selectedFile) {
            const fileData = {
                'File Name': selectedFile.name,
                'File Type': selectedFile.type,
                'Last Modified': selectedFile.lastModifiedDate.toDateString(),
            }
            return (
                <div>
                    <h2 className="avidly-font">File Details:</h2>
                    <JsonTable json={fileData} />
                </div>
            );
        } else {
            return null;
        }
    };

    const renderProcessedJSON = () => {
        if (processedJSON) {
            return (
                <div>
                    <h2 className="avidly-font">Processed JSON data:</h2>
                    <JsonTable json={processedJSON} />
                </div>
            );
        }
    }

    const renderErrors = () => {
        if (errors) {
            const title = errors.status ? 'Errors' : 'Mismatched companies';
            return (
                <div>
                    <h2 className="avidly-font">{title}:</h2>
                    {/* <div className="errorButtons">
                        <button onClick={() => downloadObjectAsJson(errors, `errors_${errors.id}`)}>Download JSON</button>
                    </div> */}
                    <JsonTable json={errors} />
                </div>
            );
        }
    }

    const renderLoader = () => {
        if (isLoading) {
            return (
                <div className="loader-wrapper">
                    <div className="loader"></div>
                    <div className="loader-label">Processing...</div>
                </div>
            );
        }
    }

    return (
        <>
            <div className="file-upload-wrapper">
                <div className={`file-upload`}>
                    <div className="file-select">
                        <div className="file-select-button" id="fileName">Choose File</div>
                        <div className="file-select-name" id="noFile">
                            {selectedFile ? selectedFile.name : 'No file chosen...'}
                        </div> 
                        <input type="file" id="chooseFile" name="xmlFile" accept=".xlsx,.xls" onChange={onFileChange}/>
                    </div>
                    <button onClick={onFileUpload} className="file-upload-button" disabled={!selectedFile || isLoading}>
                        <span className="file-upload-icon"><UploadOutlined /></span>
                    </button>
                </div>
            </div>
            {renderFileInfo()}
            {renderLoader()}
            {renderProcessedJSON()}
            {renderErrors()}
        </>
    );
}

export default Home;
