import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    useLocation
} from 'react-router-dom';
import Axios from 'axios';

import Home from './views/Home';
import Mismatches from './views/Mismatches';
import Unauthorized from './components/Unauthorized';
import { API_URL } from './config';
import Navigation from './components/Navigation';

const UnauthorizedRoute = () => <Route path="/*" exact component={Unauthorized}/>;

const RenderRoutes = () => {
    const [account, setAccount] = useState();
    const location = useLocation();
    const apiKey = new URLSearchParams(location.search).get('api_key');

    useEffect(() => {
        (async () => {
            const account = await Axios.post(`${API_URL}/api/auth/validateRequest`, { apiKey });
            setAccount(account.data);
        })();
    }, [apiKey]);

    return (
        account ?
        <>
            <Navigation/>
            <Switch>
                <Route
                    path="/mismatches"
                    exact
                    component={() => Mismatches({ account, apiKey })}
                />
                <Route
                    path="/home"
                    exact
                    component={() => Home({ account, apiKey })}
                />
                <UnauthorizedRoute/>
            </Switch>
        </>
        : <UnauthorizedRoute/>
    );
};

const Routes = () => (
    <Router>
        <RenderRoutes/>
    </Router>
);

export default Routes;