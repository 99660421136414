import axios from 'axios';
import { useState, useEffect } from 'react';
import SearchIcon from '@ant-design/icons/SearchOutlined';

import '../assets/Mismatches.css';
import { API_URL } from '../config';
import JsonTable from '../components/JsonTable';

function Mismatches({ apiKey }) {
    const [mismatchGuid, setMismatchGuid] = useState('');
    const [mismatches, setMismatches] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const searchMismatches = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/api/importErrors`, { params: { apiKey, errorGuid: mismatchGuid, limit: 10 } });
            setMismatches(response.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setMismatches([]);
            setIsLoading(false);
        }
    };

    const renderMismatches = () => {
        return (
            <>
                <div className="mismatches-search">
                    <span className="mismatches-wrapper">
                        <label htmlFor="name" className="basic-slide-label">Record ID:</label>
                        <input 
                            className="basic-slide" 
                            id="errorId" 
                            type="text" 
                            placeholder="Unique ID of the mismatch"
                            value={mismatchGuid} 
                            onChange={(e) => setMismatchGuid(e.target.value)} 
                        />
                        <button className="mismatches-search-button" onClick={searchMismatches}>
                            <span className="mismatches-search-icon"><SearchIcon/></span>
                        </button>
                    </span>
                </div>
                {
                    isLoading ?
                    <div className="loader-wrapper">
                        <div className="loader"/>
                        <div className="loader-label">Loading...</div>
                    </div> :
                    <div>
                        <h2 className="avidly-font">The latest mismatched companies:</h2>
                        <h4 className="avidly-font">Please note that only 10 latest records are shown! You can search for older mismatches by the unique ID.</h4>
                        <JsonTable json={mismatches} />
                    </div>
                }
            </>
        )
    };

    useEffect(() => {
        (async () => {
            searchMismatches(apiKey, mismatchGuid);
        })();
    }, [apiKey]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            {renderMismatches()}
        </>
    );
}

export default Mismatches;