import { useLocation, NavLink } from 'react-router-dom';
import HomeIcon from '@ant-design/icons/HomeOutlined';

import '../assets/Navigation.css';

function Navigation() {
    const location = useLocation();
    const apiKey = new URLSearchParams(location.search).get('api_key');
    return (
        <ul>
            <li>
                <NavLink to={`/home?api_key=${apiKey}`}><HomeIcon/></NavLink>
            </li>
            <li>
                <NavLink to={`/mismatches?api_key=${apiKey}`}>Mismatched companies</NavLink>
            </li>
        </ul>
    );
}

export default Navigation;